import React from 'react'
import Helmet from 'react-helmet'

import {
  Layout,
  BannerSmall,
  Image,
  BannerLinks,
  Breadcrumbs,
  TextBox,
  BlogSlider
} from '../../components'

import cards from '../../components/BlogSlider/DefaultCards'
import boxes from '../../components/BannerLinks/DefaultBox'

import BannerImage from '../../assets/projektuebersicht.jpg'
import TakeOff from '../../assets/bannerlinks/takeoff.jpg'

import { generateBreadcrumbsLinks } from '../../utils/index'

const CMSBeratungFuerTechniker = () => {
  const links = generateBreadcrumbsLinks('/blog/cms-beratung-fuer-techniker', 'CMS Beratung für Techniker')
  return (
    <Layout>
      <Helmet
        title="CMS-Beratung für Techniker"
        meta={[
          {
            name: 'description',
            content:
              'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.'
          },
          {
            name: 'keywords',
            content:
              'CMS-Beratung für Techniker holzweg, Blog holzweg, Innsbruck, holzweg, Blog, CMS-Beratung für Techniker'
          }
        ]}
      />
      <BannerSmall
        image={BannerImage}
        title={
          <span>
            Zeh ähm Ess
            <br />
            to go
          </span>
        }
      />
      <Breadcrumbs
          backLinkAlias=' '
          links={links}
        />
      <TextBox
        title="CMS-Beratung für Techniker"
        text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut."
      />
      <TextBox
        title=""
        text={
          <span>
            <h2>CMS-Beratung</h2>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.
            </p>
          </span>
        }
      />
      <Image
        image={TakeOff}
        title="Abbildung 1: Smartphone Bereiche, die mit dem Daumen erreichbar sind. Basierend auf durchschnittlicher Handgröße und Griffweite)"
      />
      <TextBox
        title=""
        text={
          <span>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.</span>
        }
      />
      <TextBox
        title=""
        text={
          <span>
            <h2>
              Mobiler <i>“sweet spot”</i>
            </h2>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.</p>
          </span>
        }
      />
      <TextBox
        title=""
        text={
          <span>
            <h2>Kritische Betrachtung und best practice</h2>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.</p>
          </span>
        }
      />
      <TextBox
        title=""
        text={
          <span>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.
            </p>
          </span>
        }
      />
      <TextBox
        title=""
        text={
          <span>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.
            </p>
          </span>
        }
      />
      <TextBox
        title=""
        text={
          <span>
            <h2>holzweg Conclusio</h2>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.
            </p>
          </span>
        }
      />
      <BlogSlider cards={cards} singleRow={true} />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default CMSBeratungFuerTechniker
